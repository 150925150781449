/* Defaults
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, hgroup, nav, section, details, summary, main, img {
  display: block; }

img,
object,
embed {
  max-width: 100%; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word; }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

.clickable,
label,
input[type=button],
input[type=submit],
button {
  cursor: pointer; }

button, input, select, textarea {
  margin: 0; }

button {
  width: auto;
  overflow: visible; }

.ie7 img {
  -ms-interpolation-mode: bicubic; }

.ie6 html {
  filter: expression(document.execCommand("BackgroundImageCache", false, true)); }

.chromeframe {
  position: absolute;
  top: 0; }

.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

* {
  box-sizing: border-box; }

/* The font-size mixin */
/* ------------------------------------ */

.spacer {
  float: none;
  clear: both;
  margin: 0;
  border: 0;
  padding: 0;
  width: 0;
  height: 0; }

/* The vertical-align mixin */
/* @include to vertically align an element i.e btn */
body,
html {
  min-height: 100%; }

body {
  background-color: #fefeff;
  overflow-x: hidden; }

* {
  box-sizing: border-box; }

html,
body {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none;
  color: #466af7; }
  a:hover {
    text-decoration: none; }

p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  font-style: normal;
  margin: 0 0 30px; }
  p strong {
    font-weight: 600; }
  p em {
    font-style: italic; }

h1,
h2 {
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 1.19048;
  letter-spacing: 0px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 400;
  font-style: normal;
  margin: 0 0 10px; }
  h1 strong,
  h2 strong {
    font-weight: 400; }
  h1 em,
  h2 em {
    font-style: normal; }

h3 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.21429;
  letter-spacing: 0px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 400;
  font-style: normal;
  margin: 0 0 10px; }
  h3 strong {
    font-weight: 400; }
  h3 em {
    font-style: normal; }

h4 {
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 1.21739;
  letter-spacing: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 600;
  font-style: normal;
  margin: 0 0 10px; }
  h4 strong {
    font-weight: 600; }
  h4 em {
    font-style: normal; }

.wp-block-table {
  margin: 0 0 30px;
  width: 100%; }
  .wp-block-table tr {
    background-color: rgba(70, 106, 247, 0.05); }
    .wp-block-table tr:nth-child(odd) {
      background-color: rgba(70, 106, 247, 0.1); }
  .wp-block-table td {
    border: 0;
    padding: 10px;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #333;
    font-weight: 300;
    font-style: normal; }

ol,
ul {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  margin: 0 0 30px;
  padding: 0;
  counter-reset: item;
  font-weight: 300;
  font-style: normal;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #333; }

ul li {
  position: relative;
  list-style: none;
  padding-left: 25px;
  margin-bottom: 12px; }
  ul li::before {
    position: absolute;
    top: 12px;
    left: 0;
    width: 10px;
    height: 1px;
    background-color: #333;
    content: ""; }

ol li {
  position: relative;
  counter-increment: item;
  list-style: none;
  padding-left: 25px;
  margin-bottom: 12px; }
  ol li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: counters(item, "") ".";
    color: #333; }

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1300px; }
  @media screen and (min-width: 850px) {
    .container {
      padding: 0 60px; } }
  .container.container--md {
    max-width: 1000px; }
  .container.container--min {
    max-width: 800px; }

main {
  background-color: #fefeff; }

@media screen and (min-width: 850px) {
  .alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    max-width: 1000%; }
  .alignwide {
    margin-left: calc(25% - 25vw);
    margin-right: calc(25% - 25vw);
    width: calc(50% + 50vw);
    max-width: 1000%; }
  .alignwide img,
  .alignfull img {
    display: block;
    margin: 0 auto; }
  .wp-block-table.has-fixed-layout.alignfull {
    width: 100vw; }
  .wp-block-table.has-fixed-layout.alignwide {
    width: calc(50% + 50vw); } }

.section {
  position: relative;
  padding: 60px 0; }
  @media screen and (min-width: 850px) {
    .section {
      padding: 100px 0; } }

.section--white {
  background-color: #fff; }

.section--smoke {
  background-color: #f4f6fb; }

.section--border {
  border-bottom: 2px solid #f4f6fb; }

.section--flat {
  padding: 60px 0 0; }
  @media screen and (min-width: 850px) {
    .section--flat {
      padding: 100px 0 0; } }

@media screen and (min-width: 850px) {
  .section__columns {
    display: grid; } }

.section__columns--2 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px; }

.section__columns--3 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px; }

.section__column {
  margin-bottom: 40px; }
  .section__column:last-child {
    margin: 0; }
  @media screen and (min-width: 850px) {
    .section__column {
      margin: 0; } }

.section__header {
  padding-bottom: 60px; }
  @media screen and (min-width: 850px) {
    .section__header {
      padding-bottom: 80px; } }

.section__header--min {
  padding-bottom: 40px; }
  @media screen and (min-width: 850px) {
    .section__header--min {
      padding-bottom: 40px; } }

.section__headline {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  color: #466af7; }
  @media screen and (min-width: 850px) {
    .section__headline {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 1.3;
      letter-spacing: 1.5px; } }

.section__byline {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.46154;
  letter-spacing: 1px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000; }
  @media screen and (min-width: 850px) {
    .section__byline {
      font-size: 32px;
      font-size: 2rem;
      line-height: 1.5;
      letter-spacing: 1px; } }

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #213367;
  border-bottom: 8px solid #ffde00;
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  margin-bottom: 40px;
  padding: 180px 20px 40px;
  max-width: 100vw; }
  @media screen and (min-width: 850px) {
    .banner {
      margin-bottom: 60px;
      padding: 150px 20px 60px; } }
  .banner::before {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    width: 100%;
    height: 200px;
    content: ""; }
  .banner::after {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    content: ""; }

.banner__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1; }
  @media only screen and (min-width: 768px) {
    .banner__header {
      width: 65%; } }
  .banner__header h1 {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .banner__header h1.reverse .banner__headline {
      order: 1; }
    .banner__header h1.reverse .banner__byline {
      order: 2; }

.banner__headline {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 1;
  letter-spacing: 0px;
  margin: 0;
  font-family: "Playfair Display", Georgia, Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  text-align: center; }

.banner__byline {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: 0px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #fff;
  text-align: center; }

.banner__cta {
  margin-top: 20px; }
  @media screen and (min-width: 850px) {
    .banner__cta {
      margin-top: 40px; } }

.home .banner {
  border: 0;
  margin-bottom: 0;
  padding: 180px 20px 40px; }
  @media screen and (min-width: 850px) {
    .home .banner {
      padding: 260px 20px 170px; } }
  .home .banner .banner__headline {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1;
    letter-spacing: 0px; }
    @media screen and (min-width: 850px) {
      .home .banner .banner__headline {
        font-size: 80px;
        font-size: 5rem;
        line-height: 1;
        letter-spacing: 0px; } }
  .home .banner .banner__byline {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1;
    letter-spacing: 0px; }
    @media screen and (min-width: 850px) {
      .home .banner .banner__byline {
        font-size: 44px;
        font-size: 2.75rem;
        line-height: 1;
        letter-spacing: 0px; } }

.header {
  display: block;
  position: relative; }

.header__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 1; }
  @media screen and (min-width: 850px) {
    .header__wrapper {
      align-items: flex-end;
      padding: 20px 60px; } }

.footer {
  position: relative;
  margin-top: 40px;
  overflow: hidden; }
  @media screen and (min-width: 850px) {
    .footer {
      margin-top: 80px; } }

.footer__main {
  background-color: #213367;
  padding: 20px 0; }
  @media screen and (min-width: 850px) {
    .footer__main {
      padding: 40px 0; } }

.footer__columns {
  display: grid; }
  @media screen and (min-width: 850px) {
    .footer__columns {
      grid-template-columns: 1fr 1fr;
      grid-gap: 80px; } }

.footer__logo {
  justify-self: center;
  order: 2;
  margin-top: 20px; }
  @media screen and (min-width: 850px) {
    .footer__logo {
      justify-self: end;
      order: 1;
      margin-top: 30px; } }
  .footer__logo .logo {
    margin: 0; }

.footer__contact {
  order: 1; }
  @media screen and (min-width: 850px) {
    .footer__contact {
      order: 2; } }

.contact-list a {
  transition: all .25s ease 0s;
  color: white; }

.contact-list a:hover {
  color: white; }

.contact-list__headline {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33333;
  letter-spacing: 0px;
  margin-bottom: 10px;
  font-family: "Playfair Display", Georgia, Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #fff; }

.contact-list__item {
  margin-bottom: 7px; }

.contact-item {
  display: flex; }

.contact-item__headline {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0px;
  flex-shrink: 0;
  flex-basis: 70px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff; }

.logo-copyright__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 0.700rem;
  color: #fff;
  text-align: right; }

.contact-item__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #fff; }

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }
  @media screen and (min-width: 850px) {
    .footer-social {
      justify-content: start; } }
  .footer-social .social-links__icon svg {
    width: 24px; }

.footer__menu {
  background-color: #182754;
  padding: 23px 0; }

.footer-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  margin: 0;
  padding: 0; }
  @media screen and (min-width: 850px) {
    .footer-menu {
      display: flex; } }
  .footer-menu .menu-item {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0px;
    position: relative;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .footer-menu .menu-item::before {
      display: none; }
    @media screen and (min-width: 850px) {
      .footer-menu .menu-item {
        padding: 0 15px; } }
  .footer-menu a {
    transition: all .25s ease 0s;
    color: white; }
  .footer-menu a:hover {
    color: white; }

.footer-menu__wrapper {
  display: flex;
  justify-content: center; }

.footer-menu__container {
  max-width: 500px; }
  @media screen and (min-width: 850px) {
    .footer-menu__container {
      max-width: none; } }

.social-links {
  display: flex;
  align-items: center;
  margin-top: 20px; }

.social-links__icon {
  transition: all .25s ease 0s;
  margin: 0 10px;
  opacity: 1; }
  @media screen and (min-width: 850px) {
    .social-links__icon {
      margin: 0 20px 0 0; } }
  .social-links__icon:hover {
    opacity: 0.8; }
  .social-links__icon svg {
    display: block;
    width: 24px;
    height: 24px; }

.btn {
  transition: all .25s ease 0s;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;
  background-color: #466af7;
  border-radius: 5px;
  height: 40px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 300;
  font-style: normal; }
  @media screen and (min-width: 850px) {
    .btn {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0px;
      padding: 0 30px;
      height: 50px; } }
  .btn:hover {
    background-color: #2e56f6; }
  .btn:focus {
    background-color: #2e56f6; }
  .btn.btn--secondary {
    background-color: #466af7; }
    .btn.btn--secondary:hover {
      background-color: #2e56f6; }
    .btn.btn--secondary:focus {
      background-color: #2e56f6; }
  .btn.btn--sm {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1;
    letter-spacing: 0px;
    height: 36px; }
    @media screen and (min-width: 850px) {
      .btn.btn--sm {
        padding: 0 25px;
        height: 36px; } }
  .btn.btn--outline {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0px;
    background-color: transparent;
    border: 1px solid #333;
    height: 32px;
    color: #333; }
    .btn.btn--outline:hover {
      background-color: transparent;
      border: 1px solid #000;
      color: #000; }
    .btn.btn--outline:focus {
      background-color: transparent;
      border: 1px solid #000;
      color: #000; }
  .btn.btn--center {
    margin: 0 auto; }

.logo {
  display: block;
  align-self: flex-start;
  background-image: url("../img/sarnia-logo.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-bottom: 30px;
  width: 162px;
  height: 63px;
  text-indent: -999em; }
  @media screen and (min-width: 850px) {
    .logo {
      position: absolute;
      left: 60px;
      margin: 0;
      width: 216px;
      height: 84px; } }
  .logo svg {
    display: block;
    width: 162px; }
    @media screen and (min-width: 850px) {
      .logo svg {
        width: 216px; } }

@media screen and (min-width: 850px) {
  .footer .logo {
    position: relative;
    left: auto; } }

.primary-menu {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #213367;
  width: 250px;
  height: 100vh;
  z-index: -1; }
  @media screen and (min-width: 850px) {
    .primary-menu {
      display: block;
      position: relative;
      margin-top: -50px;
      margin-right: 35px;
      top: 120px;
      height: auto;
      width: auto;
      background-color: transparent;
      pointer-events: none; } }
  .primary-menu .menu {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (min-width: 850px) {
      .primary-menu .menu {
        display: flex;
        justify-content: flex-end; } }
    .primary-menu .menu li {
      margin: 0;
      padding: 0; }
      .primary-menu .menu li a {
        display: flex;
        align-items: center;
        padding: 0 25px;
        height: 50px;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1;
        letter-spacing: 0px;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        color: #fff;
        font-weight: 300;
        font-style: normal;
        pointer-events: auto; }
    .primary-menu .menu li::before {
      display: none; }

.primary-menu__toggle {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 40px;
  height: 25px;
  border-top: 1px solid #fff;
  border-right: 0;
  border-bottom: 1px solid #fff;
  border-left: 0;
  background-color: transparent;
  text-indent: -999em; }
  @media screen and (min-width: 850px) {
    .primary-menu__toggle {
      display: none; } }
  .primary-menu__toggle::after {
    position: absolute;
    top: 11px;
    left: 0;
    width: 40px;
    height: 1px;
    background-color: #fff;
    content: ""; }

.wrap {
  transition: all .25s ease 0s;
  transition: transform 0.25s ease 0s;
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0); }
  @media screen and (min-width: 850px) {
    .wrap {
      z-index: 1; } }
  .wrap.js-nav_is_open {
    transform: translate3d(-250px, 0, 0); }
    @media screen and (min-width: 850px) {
      .wrap.js-nav_is_open {
        transform: translate3d(0, 0, 0); } }
  .wrap.js-nav_is_visible .primary-menu {
    opacity: 1; }
  @media screen and (min-width: 850px) {
    .wrap {
      box-shadow: none; } }

.top {
  position: relative;
  z-index: 1; }
  @media screen and (min-width: 850px) {
    .top {
      z-index: 2; } }

.feature-notification {
  transition: transform 0.25s ease 0s; }
  .feature-notification.js-nav_is_open {
    transform: translate3d(-250px, 0, 0); }
    @media screen and (min-width: 850px) {
      .feature-notification.js-nav_is_open {
        transform: translate3d(0, 0, 0); } }

.accordion {
  margin-bottom: 30px;
  border: 1px solid #f4f6fb;
  background-color: #fff;
  cursor: pointer; }
  @media screen and (min-width: 850px) {
    .accordion {
      margin-bottom: 20px; } }

.accordion__header {
  position: relative;
  padding: 20px; }

.accordion__toggle {
  transition: all .25s ease 0s;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -10px;
  height: 21px;
  width: 21px; }
  .accordion__toggle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    width: 1px;
    height: 100%;
    background-color: #466af7; }
  .accordion__toggle::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #466af7; }

.accordion__headline {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: 0px;
  margin: 0;
  padding-right: 61px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 300;
  font-style: normal; }

.accordion__content {
  display: none;
  padding: 0 20px 20px 20px; }
  .accordion__content > :last-child {
    margin-bottom: 0; }

.js-accordion__toggle.js-active .accordion__toggle {
  transform: rotate(45deg); }

.js-accordion__toggle.js-active .accordion__content {
  display: block; }

.post-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #f4f6fb;
  margin: 0 -21px 30px;
  height: 100%; }
  @media screen and (min-width: 600px) {
    .post-card {
      margin: 0 0 40px; } }
  .post-card.card--blue .card__main {
    border-top: 6px solid #466af7; }
  .post-card.card--red .card__main {
    border-top: 6px solid #b11e3a; }
  .post-card.card--yellow .card__main {
    border-top: 6px solid #ffde00; }

.card__image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 120px; }
  .card__image.card__image--portrait {
    height: auto;
    padding-bottom: 100%; }

.card__main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px; }
  @media screen and (min-width: 850px) {
    .card__main {
      padding: 30px; } }

.card__headline {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: 0px;
  margin-bottom: 5px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 300;
  font-style: normal; }
  .card__headline a {
    text-decoration: none;
    color: #333; }
    .card__headline a:hover {
      text-decoration: none; }

.card__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.57143;
  letter-spacing: 0px;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  font-style: normal; }
  .card__text p {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.57143;
    letter-spacing: 0px;
    margin: 0 0 20px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #333;
    font-weight: 300;
    font-style: normal; }
  .card__text > :last-child {
    margin-bottom: 0; }

.card__cta {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 20px; }
  @media screen and (min-width: 850px) {
    .card__cta {
      padding-top: 30px; } }

.wp-block-column {
  margin: 0; }

.news-card {
  background-color: #fff;
  border: 1px solid #f4f6fb;
  margin: 0 -21px 30px;
  height: 100%; }
  @media screen and (min-width: 600px) {
    .news-card {
      margin: 0 0 40px; } }
  .news-card.card--blue .news-card__main {
    border-top: 6px solid #466af7; }
  .news-card.card--red .news-card__main {
    border-top: 6px solid #b11e3a; }
  .news-card.card--yellow .news-card__main {
    border-top: 6px solid #ffde00; }
  @media screen and (min-width: 850px) {
    .news-card.alignwide {
      margin-left: calc(25% - 25vw);
      margin-right: calc(25% - 25vw);
      width: auto;
      max-width: 1000%; } }

.news-card__image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 120px; }

.news-card__main {
  padding: 20px; }
  @media screen and (min-width: 850px) {
    .news-card__main {
      padding: 30px; } }

.news-card__headline {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333;
  letter-spacing: 0px;
  margin-bottom: 15px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 400;
  font-style: normal; }
  .news-card__headline a {
    text-decoration: none;
    color: #333; }
    .news-card__headline a:hover {
      text-decoration: none; }

.news-card-list {
  margin: 0; }

.news-card-list__item {
  margin: 0 0 25px;
  padding: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }
  .news-card-list__item::before {
    display: none; }
  .news-card-list__item:last-child {
    margin: 0; }
  .news-card-list__item p {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.28571;
    letter-spacing: 0px;
    margin: 0; }
  .news-card-list__item a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.125;
    letter-spacing: 0px;
    margin-bottom: 5px;
    font-weight: 600;
    text-decoration: none;
    color: #466af7; }
    .news-card-list__item a:hover {
      text-decoration: none; }

.navigation-card {
  background-color: #fff;
  border: 1px solid #f4f6fb;
  margin: 0 -21px 30px;
  height: 100%; }
  @media screen and (min-width: 600px) {
    .navigation-card {
      margin: 0 0 40px; } }
  .navigation-card.card--blue .navigation-card__main {
    border-top: 6px solid #466af7; }
  .navigation-card.card--red .navigation-card__main {
    border-top: 6px solid #b11e3a; }
  .navigation-card.card--yellow .navigation-card__main {
    border-top: 6px solid #ffde00; }

.navigation-card__image {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 120px; }

.navigation-card__main {
  padding: 20px; }
  @media screen and (min-width: 850px) {
    .navigation-card__main {
      padding: 30px; } }

.navigation-card__headline {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.33333;
  letter-spacing: 0px;
  margin-bottom: 15px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 400;
  font-style: normal; }

.navigation-card__menu .menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 20px 30px;
  margin: 0; }

.navigation-card__menu .sub-menu {
  margin: 0; }

.navigation-card__menu .menu-item {
  margin: 0;
  padding: 0;
  border-top: 1px solid #cfd0d3;
  padding-top: 10px; }
  .navigation-card__menu .menu-item::before {
    display: none; }
  .navigation-card__menu .menu-item a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.28571;
    letter-spacing: 0px;
    display: block;
    margin: 0 0 10px;
    text-decoration: none;
    color: #333;
    font-weight: 600;
    font-family: "Open Sans", Helvetica, Arial, sans-serif; }
    .navigation-card__menu .menu-item a:hover {
      text-decoration: none; }

.navigation-card__menu .sub-menu .menu-item {
  border: 0;
  padding: 0; }
  .navigation-card__menu .sub-menu .menu-item a {
    color: #466af7;
    font-weight: 400; }

.shortcut-menu {
  background-color: #fff;
  height: 100%;
  margin: 25px 25px;
  border-top: 6px solid;
  border-image: linear-gradient(to right, blue 33.33%, #b11e3a 33.33%, #b11e3a 66.66%, #ffde00 66.66%, #ffde00 100%, #466af7 100%) 1;
  outline: 1px solid #f4f6fb; }
  @media screen and (min-width: 850px) {
    .shortcut-menu.alignwide {
      margin-left: calc(25% - 25vw);
      margin-right: calc(25% - 25vw);
      width: auto;
      max-width: 1000%; } }
  .shortcut-menu ul {
    display: flex;
    flex-wrap: wrap;
    text-align: justify;
    justify-content: center; }
    .shortcut-menu ul li {
      display: inline-block;
      align-items: center;
      padding: 0px 12.5px 12.5px 12.5px;
      text-align: center;
      margin-block: auto;
      max-width: 150px;
      word-wrap: break-word; }
      .shortcut-menu ul li::before {
        position: relative; }
    .shortcut-menu ul img {
      max-width: 100%;
      height: auto; }
  .shortcut-menu .caption {
    display: block; }

.wp-block-gallery {
  margin: 0; }

.blocks-gallery-item {
  padding: 0; }
  .blocks-gallery-item::before {
    display: none; }

.wp-block-file {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #f4f6fb;
  padding: 20px; }
  @media screen and (min-width: 850px) {
    .wp-block-file {
      flex-direction: row;
      justify-content: space-between; } }
  .wp-block-file a {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33333;
    letter-spacing: 0px;
    margin-bottom: 10px;
    font-family: "Playfair Display", Georgia, Times, serif;
    color: #333;
    font-weight: 400;
    font-style: normal;
    align-self: start; }
    @media screen and (min-width: 850px) {
      .wp-block-file a {
        margin: 0 !important; } }

.wp-block-file .wp-block-file__button {
  transition: all .25s ease 0s;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;
  background-color: #466af7;
  border-radius: 5px;
  height: 40px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 300;
  font-style: normal; }
  .wp-block-file .wp-block-file__button:hover {
    background-color: #2e56f6; }
  .wp-block-file .wp-block-file__button:focus {
    background-color: #2e56f6; }

.wp-block-file * + .wp-block-file__button {
  margin: 0 !important; }

.notification-list {
  padding-bottom: 100px; }

.notification-list__item {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 10px;
  border-bottom: 1px solid #cfd0d3;
  margin-bottom: 25px;
  padding: 0 0 20px; }
  @media screen and (min-width: 850px) {
    .notification-list__item {
      grid-template-columns: 32px 1fr 3fr; } }
  .notification-list__item::before {
    display: none; }
  .notification-list__item:last-child {
    margin: 0; }

.notification__header {
  padding-right: 20px; }

.notification__headline {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  margin-bottom: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #333; }

.notification__date {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  margin-bottom: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }

.notification__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  grid-column: span 2;
  margin-bottom: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }
  @media screen and (min-width: 850px) {
    .notification__text {
      grid-column: auto; } }

.notification__icon {
  width: 32px;
  height: 32px; }
  .notification__icon svg {
    fill: #333; }

.notification-btn {
  text-align: center;
  margin-bottom: 40px; }

.recent-notifications {
  position: relative;
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  margin-bottom: 0;
  max-width: 100vw;
  overflow-x: scroll; }
  @media screen and (min-width: 850px) {
    .recent-notifications {
      margin-bottom: 25px;
      overflow: auto; } }

.recent-notification-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 300%; }
  @media screen and (min-width: 850px) {
    .recent-notification-list {
      width: 100%; } }

.recent-notification-list--top {
  background-color: #ffde00; }
  .recent-notification-list--top .recent-notification-list {
    background-color: #ffde00; }

.recent-notification-list--bottom {
  background-color: #fff5b2; }
  .recent-notification-list--bottom .recent-notification-list {
    background-color: #fff5b2; }

.recent-notification-list__item {
  display: flex;
  position: relative;
  padding: 10px 10px 10px 20px;
  width: 100%; }
  .recent-notification-list__item:first-child {
    justify-content: flex-start;
    width: 150px;
    flex-shrink: 0;
    padding: 10px 0 0 0; }
  .recent-notification-list__item:nth-child(3), .recent-notification-list__item:nth-child(4) {
    box-shadow: inset 8px 0 10px -6px rgba(0, 0, 0, 0.15); }
  .recent-notification-list__item:last-child {
    justify-content: flex-end;
    width: 150px;
    flex-shrink: 0;
    padding: 10px 0 0 0; }

.recent-notification-list--collapse {
  display: none; }
  @media screen and (min-width: 850px) {
    .recent-notification-list--collapse {
      display: flex; } }

.recent-notification__icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 10px; }
  .recent-notification__icon svg {
    fill: #333; }

.recent-notification__headline {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.28571;
  letter-spacing: 0px;
  margin-bottom: 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #333; }

.recent-notification__date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0px;
  margin-bottom: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }

.recent-notification__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857;
  letter-spacing: 0px;
  margin-bottom: 0;
  padding-left: 32px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.feature-notification {
  background-color: #fff5b2;
  border-bottom: 5px solid #ffde00;
  padding: 20px 0; }

.feature-notification__wrapper {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 20px; }

.feature-notification__headline {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33333;
  letter-spacing: 0px;
  margin-bottom: 5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #333; }

.feature-notification__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  margin-bottom: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }

.feature-notification__icon {
  width: 32px;
  height: 32px; }
  .feature-notification__icon svg {
    fill: #333; }

.gform_wrapper {
  margin: 0 0 40px !important; }

.gform_wrapper .gform_heading {
  margin-bottom: 10px !important; }

.gform_wrapper h3.gform_title {
  margin: 10px 0 5px !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 32px !important;
  color: #333 !important; }

.gform_wrapper span.gform_description {
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  line-height: 24px !important;
  color: #333 !important; }

.gform_wrapper input,
.gform_wrapper textarea {
  border: 1px solid #cfd0d3 !important;
  padding: 5px 10px !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  line-height: 24px !important;
  color: #333 !important; }

.gfield_label {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 20px !important;
  color: #000 !important; }

.ginput_complex.ginput_container label {
  margin: 0 !important;
  color: #333 !important; }

.gform_wrapper ul li.gfield {
  padding: 15px 0 0 !important; }

.gform_wrapper li.gfield select {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 400px !important;
  color: #333 !important; }

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type="checkbox"],
.gform_wrapper.gf_browser_chrome .gfield_radio li input[type="radio"] {
  margin-top: 0 !important; }

.gform_wrapper ul.gfield_radio li label,
.gform_wrapper ul.gfield_checkbox li label {
  margin: 0 0 0 8px; }

.gform_wrapper .gform_footer input[type="submit"] {
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #466af7;
  border: 0 !important;
  padding: 0 20px !important;
  border-radius: 5px !important;
  height: 40px !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #fff !important;
  font-weight: 300 !important;
  font-style: normal !important; }
  .gform_wrapper .gform_footer input[type="submit"]:hover {
    background-color: #2e56f6; }
  .gform_wrapper .gform_footer input[type="submit"]:focus {
    background-color: #2e56f6; }

.lwptoc {
  background-color: #fff;
  border: 1px solid #f4f6fb;
  padding: 20px;
  margin: 0 0 30px; }
  @media screen and (min-width: 850px) {
    .lwptoc {
      padding: 30px; } }
  .lwptoc li,
  .lwptoc li li {
    margin-bottom: 10px; }
  .lwptoc ul {
    margin: 20px 0 0 0; }
  .lwptoc ul ul {
    margin: 10px 0 10px 30px; }

.lwptoc_i {
  display: block !important;
  background-color: #fff !important;
  padding: 0 !important; }

.lwptoc_header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%; }

.lwptoc_title {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.21429;
  letter-spacing: 0px;
  font-family: "Playfair Display", Georgia, Times, serif;
  color: #333;
  font-weight: 400;
  font-style: normal; }

.lwptoc_items li::before {
  display: none !important; }

.lwptoc-light .lwptoc_items a {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  font-style: normal; }

.lwptoc-light .lwptoc_items a:link {
  color: #333; }

.lwptoc-light .lwptoc_items a:visited {
  color: #333; }

.lwptoc_item_number {
  color: #666 !important; }

.lwptoc_toggle {
  display: none; }

/* pulled from 2019 theme */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; }

.search-result {
  background: #fff;
  border: 1px solid #f4f6fb;
  color: #000;
  margin-bottom: 25px;
  padding: 30px; }

.search-result__title {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-family: "Playfair Display", Georgia, Times, serif;
  font-weight: 400;
  color: #333;
  text-decoration: none; }
  .search-result__title .search-result__highlight {
    color: #466af7; }
  .search-result__title a {
    color: #000; }

.search-result__url {
  color: #b11e3a;
  display: block;
  font-size: 13px;
  padding-bottom: 2px; }

.search-result__excerpt {
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #333; }
  .search-result__excerpt p:last-child {
    margin-bottom: 0; }
  .search-result__excerpt .search-result__highlight {
    font-weight: bold; }

.pagination .nav-links {
  text-align: center; }

.search-form {
  width: 100%;
  max-width: 440px; }
  @media screen and (min-width: 850px) {
    .search-form {
      width: auto;
      min-width: 440px; } }
  .home .search-form {
    align-self: center; }
    @media screen and (min-width: 850px) {
      .home .search-form {
        margin-top: 150px; } }

.searchform div {
  display: flex; }

.searchform input[type="search"] {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #466af7;
  border-right: none;
  border-radius: 8px 0 0 8px;
  color: #333;
  flex: 1 1 auto;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  height: 50px;
  line-height: 24px;
  margin: 0;
  padding: 10px 20px; }
  .header__wrapper .searchform input[type="search"] {
    border-color: #fff; }

.searchform input[type="submit"] {
  display: none; }

.searchform svg {
  fill: #fff;
  height: 50px;
  width: 60px;
  background-color: #466af7;
  border-radius: 0 8px 8px 0;
  padding: 10px 20px; }

ul.ui-autocomplete {
  z-index: 1000;
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px; }
  ul.ui-autocomplete li:before {
    display: none; }
  ul.ui-autocomplete .ui-state-focus {
    border: none;
    background: #466af7;
    font-weight: normal;
    color: #fff; }

.post-list__item {
  margin: 0 0 20px;
  padding: 20px;
  border: 1px solid #f4f6fb;
  background-color: #fff; }
  @media screen and (min-width: 850px) {
    .post-list__item {
      margin: 0 0 25px;
      padding: 30px; } }

.post-list__excerpt {
  margin: 0; }

.pagination {
  display: flex;
  justify-content: center; }
  .pagination .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    padding: 0 10px;
    border: 1px solid #466af7;
    height: 40px;
    width: 40px; }
    .pagination .page-numbers.current {
      border: 1px solid #182754;
      color: #182754; }
  .pagination .prev {
    width: auto; }
  .pagination .next {
    width: auto; }

@media (min-width: 600px) {
  .wp-block-columns {
    flex-wrap: nowrap; } }

.wp-block-column {
  display: flex;
  flex-direction: column; }
  @media (min-width: 600px) {
    .wp-block-column:not(:first-child) {
      margin-left: 25px; }
    .wp-block-column:nth-child(odd) {
      margin-left: 0 auto; }
    .wp-block-column:not(:last-child) {
      margin-right: 25px; } }

/* Wordpress reset
-------------------------------------------------------------------------------*/
#page-wrap {
  width: 960px;
  margin: 20px auto; }

ol.commentlist {
  list-style: none; }

ol.commentlist li.comment {
  border-bottom: 1px dotted #666;
  padding: 10px; }

ol.commentlist li.comment div.vcard cite.fn {
  font-style: normal; }

ol.commentlist li.comment div.vcard img.avatar {
  float: right;
  margin: 0 0 10px 10px; }

ol.commentlist li.comment div.comment-meta {
  font-size: 10px; }

ol.commentlist li.comment div.comment-meta a {
  color: #ccc; }

ol.commentlist li.comment div.reply {
  font-size: 11px; }

ol.commentlist li.comment div.reply a {
  font-weight: bold; }

ol.commentlist li.comment ul.children {
  list-style: none;
  margin: 10px 0 0; }

ol.commentlist li.comment ul.children li.depth-2 {
  border-left: 5px solid #555;
  margin: 0 0 10px 10px; }

ol.commentlist li.comment ul.children li.depth-3 {
  border-left: 5px solid #999;
  margin: 0 0 10px 10px; }

ol.commentlist li.comment ul.children li.depth-4 {
  border-left: 5px solid #bbb;
  margin: 0 0 10px 10px; }

ol.commentlist li.even {
  background: #fff; }

ol.commentlist li.odd {
  background: #f6f6f6; }

ol.commentlist li.parent {
  border-left: 5px solid #111; }
